import './SyllabusPage.css';
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {dataUrl, ApiKey} from '../config.js';
import Spinner from './Spinner.js';
import Subscribe from './Subscribe.js'


function SyllabusPage({backToLogin, subject, loadContentPage, TKfilter = null, TopicsInTK = null, revisions=null }) {
  const expandControl = "ExpandControl";
  const groupselindex = "groupselindex";

  const [syllabus, setSyllabus] = useState(null);
  const [subgroupingButtons, setsubGroupingButtons] = useState(null);
  const [activegroupingButtonIndex, setactivegroupingButtonIndex] = useState(null);
  const [toggleControlMaster, settoggleControlMaster] = useState({toggleControlMasterHandler: toggleControlHandler, expansionControl: null, groupingIndex: null});

  useEffect(()=>{
    // No grouping is needed for search.
    if(TKfilter!=null)
      {
        setSyllabus(subject.Syllabus);
        setsubGroupingButtons(null);
        return;
      }
    if(subject.TryGroupingSpaceDashSpace!==null && subject.TryGroupingSpaceDashSpace)
  {
    var regex = /^[^-]*/;
    var temp = Object.values(subject.Syllabus).filter(displayName => Object.keys(displayName)[0].match(regex));
    var GroupableSyllabus = new Set( temp.map(item => Object.keys(item)[0].match(regex)[0])); 
    var nonGroupableSyllabus = Object.values(subject.Syllabus).filter(displayName => !Object.keys(displayName)[0].match(regex));
    if(nonGroupableSyllabus.length>=1)
      GroupableSyllabus.add('Others');
    setsubGroupingButtons(GroupableSyllabus);
    setactivegroupingButtonIndex(0);
    var indexCache = sessionStorage.getItem(subject.SubjectName+ groupselindex);
    if(indexCache!=null)
      {
      setactivegroupingButtonIndex(parseInt(indexCache));
      var expansionControlCache = sessionStorage.getItem(subject.SubjectName+indexCache.toString()+expandControl);
      if(expansionControlCache!=null)
        {
          settoggleControlMaster(prevState => {
            return {
          ...prevState,
          expansionControl: expansionControlCache
            }
          });
        }
      }
  }
  else{

    settoggleControlMaster(prevState => {
      return {
    ...prevState,
    expansionControl: sessionStorage.getItem(subject.SubjectName+expandControl)
      }
    });
    setSyllabus(subject.Syllabus);
    setsubGroupingButtons(null);
  }
  }, [subject]);

  useEffect(() => {
    if(subgroupingButtons) {
      DisplaySyllabusGroup(sessionStorage.getItem(subject.SubjectName+groupselindex) ? parseInt(sessionStorage.getItem(subject.SubjectName+groupselindex)) : 0);
    }
}, [subgroupingButtons]);

  function DisplaySyllabusGroup(index)
  {
    sessionStorage.setItem(subject.SubjectName+groupselindex, index.toString());
    setactivegroupingButtonIndex(index);
    var expansionControl = sessionStorage.getItem(subject.SubjectName+ index.toString() + expandControl);
    settoggleControlMaster(prevState => {
      return {
    ...prevState,
    expansionControl: expansionControl
      }
    });
    var filterstr = Array.from(subgroupingButtons)[index];
    if(filterstr==='Others')
      {
        var regex = /\s-\s/;
        setSyllabus(subject.Syllabus.filter(item =>{
          return !Object.keys(item)[0].match(regex);
        }));
        return;
      }
    setSyllabus(subject.Syllabus.filter(item =>{
      return Object.keys(item)[0].startsWith(filterstr);
    }));
  }
  useEffect(() => {
    settoggleControlMaster((prevState => {
      return{
      ...prevState,
      groupingIndex: activegroupingButtonIndex
    }}))},[activegroupingButtonIndex]);

  function toggleControlHandler(syllabuskey, groupindex = null)
  {
    sessionStorage.setItem(subject.SubjectName+ (groupindex!=null ? groupindex.toString() + expandControl : expandControl), syllabuskey);
        settoggleControlMaster(prevState => {
          if(prevState.expansionControl === syllabuskey)
          {
            sessionStorage.removeItem(subject.SubjectName+ (groupindex!=null ? groupindex.toString() + expandControl : expandControl));
            return {...prevState, expansionControl: null}
          }
          else
            return {...prevState,
              expansionControl: syllabuskey}
            })
  }
  
  return (
    <div className="SyllabusPage NoSelection">
      <h1 className="SPh1">{subject.DisplayName}</h1>
      {subgroupingButtons  && <div className="SubjectGroupingButtons">
        {Array.from(subgroupingButtons).map((value, index) =>
           <div className={activegroupingButtonIndex === index ? "SubjectGroupingButton activegroupingbutton":"SubjectGroupingButton"} key = {index} tabindex="0" onClick={() => { DisplaySyllabusGroup(index)}}>{value}</div>)
        }
      </div>}
      { syllabus && syllabus.map((syllabuselement)=> {
        if(TKfilter===null)
          return <SyllabusPanelControl key={Object.values(syllabuselement)[0]} subelement={syllabuselement} loadContentPage={loadContentPage} backToLogin={backToLogin} subject={subject.SubjectName} toggleMaster= {toggleControlMaster} revisions={revisions}/>
        if(Object.values(syllabuselement)[0].toLowerCase()===TKfilter.toLowerCase())
          return <SyllabusPanelControl key={Object.values(syllabuselement)[0]} subelement={syllabuselement} loadContentPage={loadContentPage} backToLogin={backToLogin} subject={subject.SubjectName} toggleMaster={toggleControlMaster} revisions={revisions} TopicsInTK={TopicsInTK.filter((TopicElement) => {return TopicElement.TopicKeyword===TKfilter })}/>
        else
          return <></>
      })}
    </div>
  );
}

function SyllabusPanelControl({subelement, loadContentPage, backToLogin, subject, toggleMaster, TopicsInTK = null, revisions = null})
{
  return(
    <div className="SyllabusPanelControl">
        <div onClick={()=>toggleMaster.toggleControlMasterHandler(Object.values(subelement)[0], toggleMaster.groupingIndex)} className="PanelHeader">{Object.keys(subelement)[0]}</div>
        {((TopicsInTK!= null) || (toggleMaster.expansionControl && toggleMaster.expansionControl===Object.values(subelement)[0])) && <PanelContent tK={Object.values(subelement)[0]} loadContentPage={loadContentPage} backToLogin={backToLogin} subject={subject} TopicsInTK={TopicsInTK} revisions={revisions}/>}
    </div>

  );

}
function sortByOrderID(a, b) {
      return a.OrderId - b.OrderId;
}
function PanelContent({tK, loadContentPage, backToLogin, subject, TopicsInTK = null, revisions=null})
{
  const [TopicList, setTopicList] = useState(null);
  const [revisionmap, setRevisionMap] = useState({});

  useEffect(() => {
    let newRevisionMap = revisions.reduce((obj, item) => {
      obj[item.Navigation] = new Date (item.NextRevisionDate).toLocaleDateString('en-GB');
      return obj;
    }, {});

    setRevisionMap(newRevisionMap);
  }, [revisions]);

  useEffect(()=>{
    if(TopicsInTK!==null)
    {
      TopicsInTK.sort((a, b) => sortByOrderID(a, b));
      TopicsInTK.HTMLTopicList = HTMLTopicList(TopicsInTK);
      setTopicList(TopicsInTK);
      return;
    }

    // cache topics instead refresh.
    const item = sessionStorage.getItem(tK);
    if(item)
      {
        var ssTopicsInTK = JSON.parse(item);
        let copieddata = JSON.parse(item);
        if(!ssTopicsInTK.Locked || ssTopicsInTK.Locked==="false" )
          ssTopicsInTK.HTMLTopicList = HTMLTopicList(ssTopicsInTK, copieddata);
        setTopicList(ssTopicsInTK);
        return;
      }
    
    var req = {
      email: localStorage.getItem('user'),
      method: "GetTopics",
      subject: subject,
      params:{
        TK:tK
      }
    };
    const headers = { 
      'x-api-key': ApiKey
    };

  axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp=>{

      var data = resp.data;
      if(!data.Locked)
        {
          data.Locked = false;
          data.sort((a, b) => sortByOrderID(a, b));
          let copieddata = JSON.parse(JSON.stringify(data));
          data.HTMLTopicList = HTMLTopicList(data, copieddata);
        }
      sessionStorage.setItem(tK, JSON.stringify(data));
      setTopicList(data);
  }).catch(err => {backToLogin()});
  },[revisionmap]);
  useEffect(()=>{
    if(TopicsInTK!==null)
    {
      TopicsInTK.sort((a, b) => sortByOrderID(a, b));
      TopicsInTK.HTMLTopicList = HTMLTopicList(TopicsInTK);
      setTopicList(TopicsInTK);
        }
      }, [TopicsInTK, revisionmap]);

  function HTMLTopicList(data, copieddata = null)
  {
    return data.map((topic) => <li key={topic.Topic} onClick={(e) => {loadContentPage(subject, tK, topic.Topic, topic.Type, copieddata)}} className="TopicList" >{topic.Topic}{revisionmap[subject+'^'+tK.toLowerCase()+'^'+topic.Topic+'^'+topic.Type] && (<div className='TopicListRevisionDate'>(Revision on {revisionmap[subject+'^'+tK.toLowerCase()+'^'+topic.Topic+'^'+topic.Type]})</div>)}</li>)
  }

  
  return(
    <div className="PanelContent">
      {TopicList ? (TopicList.Locked ? <Subscribe msg={TopicList.message} backToLogin={backToLogin}/> : <ul className="TopicsUl">{TopicList.HTMLTopicList}</ul>) : <Spinner wholepage={false}/>}
    </div>
  );
}

export default SyllabusPage;

